import { useQueryClient } from '@tanstack/react-query';
import { useTracking } from 'react-tracking';
import { useDispatch } from 'react-redux';

import { useSnackbar } from '@/features/snackbar';
import { PORTFOLIOS_QUERY_KEY } from '@/hooks/portfolio/usePortfolio';
import { ROUTE_PORTFOLIO } from '@/constants/routes';

import { useDuplicatePortfolioMutation } from './useDuplicatePortfolioMutation';

export const useDuplicatePortfolio = () => {
  const queryClient = useQueryClient();
  const { trackEvent } = useTracking();
  const { addSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return useDuplicatePortfolioMutation({
    onMutate: () => {
      addSnackbar({
        type: 'loading',
        message: 'Duplicating portfolio...',
        lifeTime: 2000,
      });
    },
    onSuccess: (response) => {
      trackEvent({
        action: 'success',
        modifier: 'duplicatePortfolio',
      });
      dispatch({
        type: ROUTE_PORTFOLIO,
        payload: { portfolioId: response.duplicateAsManualPortfolio.id },
      });
    },
    onError: () => {
      trackEvent({
        action: 'error',
        modifier: 'duplicatePortfolio',
      });
      addSnackbar({
        type: 'negative',
        lifeTime: 2000,
        message: 'Failed to duplicate portfolio',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: PORTFOLIOS_QUERY_KEY });
    },
  });
};
