import styled from 'styled-components';

export const Container = styled('div')`
  padding-top: ${({ theme }) => theme.x3spacePx}px;
`;
export const ButtonContainer = styled('div')`
  position: relative;
`;

export const ButtonList = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.x3spacePx}px;
`;
