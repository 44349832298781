import type { FormEvent } from 'react';
import { useState } from 'react';
import { useDuplicatePortfolio } from './hooks';
import { useTracking } from 'react-tracking';

import { Modal, Textfield } from '@simplywallst/ui-core';

import type { Portfolio } from '@/hooks/portfolio/usePortfolio';

export interface Props {
  portfolio: Portfolio;
  onClose: () => void;
}

export const DuplicatePortfolioModal = ({ onClose, portfolio }: Props) => {
  const { trackEvent } = useTracking();
  const { mutate } = useDuplicatePortfolio();
  const [portfolioName, setNewPortfolioName] = useState(
    `Copy of ${portfolio.name}`
  );
  const [nameLengthError, setNameLengthError] = useState(false);

  const onDuplicate = () => {
    trackEvent({
      action: 'click',
      modifier: 'duplicatePortfolio',
    });
    mutate({
      sourcePortfolioId: portfolio.id,
      newName: portfolioName,
    });
    onClose();
  };

  return (
    <Modal
      title="Duplicate portfolio"
      asForm={true}
      onSecondaryClick={onClose}
      onPrimaryClick={onDuplicate}
      secondaryLabel="Cancel"
      primaryLabel="Duplicate"
      primaryDisabled={nameLengthError}
    >
      <div className="grid gap-6">
        <Textfield
          name="portfolioName"
          styleType="standardReverse"
          type="text"
          label="Rename portfolio"
          value={portfolioName}
          onChange={(e: FormEvent<HTMLInputElement>) => {
            setNewPortfolioName(e.currentTarget.value);
            setNameLengthError(e.currentTarget.value.length === 0);
          }}
          error={nameLengthError ? 'Name cannot be blank' : ''}
        />
        <p className="text-xs text-legacy-spacegray">
          All duplicated portfolios will be manual portfolio (not linked to a
          broker)
        </p>
      </div>
    </Modal>
  );
};
