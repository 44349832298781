import { Text } from '@simplywallst/ui-core';
import { Container, ButtonList, ButtonContainer } from './styled';
import { Apple, Facebook, Google } from '@/components/OAuthButtons';
import { useUserAgent } from '@simplywallst/hooks/useUserAgent';
import { useOAuthButtons } from './useOAuthButtons';
import loadable from '@loadable/component';
import { useTranslation } from '@/hooks/useTranslation';

const AuthenticationErrorMessages = loadable(
  () => import('../AuthenticationErrorMessages'),
  {
    resolveComponent: (c) => c.AuthenticationErrorMessages,
  }
);

interface Props {
  type: 'login' | 'register';
  onLoginSuccess?: () => void;
  onRegisterSuccess?: () => void;
}

export function OAuthButtons({
  type,
  onLoginSuccess,
  onRegisterSuccess,
}: Props) {
  const { os } = useUserAgent();

  const { handleApple, handleFacebook, handleGoogle, status, error } =
    useOAuthButtons({
      onLoginSuccess,
      onRegisterSuccess,
    });

  const t = useTranslation();

  return (
    <Container>
      {status === 'error' && (
        <AuthenticationErrorMessages
          selectorId="login-notification-error"
          error={error}
        />
      )}
      <Text color="black" typography="small">
        {t(
          type === 'login'
            ? 'app.modal.oauth.cta.login'
            : 'app.modal.oauth.cta.registration'
        )}
      </Text>
      <ButtonList>
        <ButtonContainer>
          <Google onSuccess={handleGoogle} disabled={status === 'pending'} />
        </ButtonContainer>
        {type === 'login' && (
          <ButtonContainer>
            <Facebook
              onSuccess={handleFacebook}
              disabled={status === 'pending'}
            />
          </ButtonContainer>
        )}
        {(type === 'login' || os === 'iOS') && (
          <ButtonContainer>
            <Apple onSuccess={handleApple} disabled={status === 'pending'} />
          </ButtonContainer>
        )}
      </ButtonList>
    </Container>
  );
}
