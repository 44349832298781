import { gql } from '@/utilities/gql';
import { makeGqlMutation } from '@/utilities/gqlQuery';

const MUTATION = gql(
  `
  mutation duplicateAsManualPortfolio ($sourcePortfolioId: String!, $newName: String!) {
  duplicateAsManualPortfolio(sourcePortfolioId: $sourcePortfolioId, name: $newName) {
    id
  }
}
`
);

export const useDuplicatePortfolioMutation = makeGqlMutation(MUTATION);
